// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface PlayUpgradeRequest
 */
export interface PlayUpgradeRequest {
  /**
   *
   * @type {number}
   * @memberof PlayUpgradeRequest
   */
  origInventory: number;
  /**
   *
   * @type {number}
   * @memberof PlayUpgradeRequest
   */
  expectedItem: number;
}

/**
 * Check if a given object implements the PlayUpgradeRequest interface.
 */
export function instanceOfPlayUpgradeRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "origInventory" in value;
  isInstance = isInstance && "expectedItem" in value;

  return isInstance;
}

export function PlayUpgradeRequestFromJSON(json: any): PlayUpgradeRequest {
  return PlayUpgradeRequestFromJSONTyped(json, false);
}

export function PlayUpgradeRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PlayUpgradeRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    origInventory: json["orig_inventory"],
    expectedItem: json["expected_item"],
  };
}

export function PlayUpgradeRequestToJSON(
  value?: PlayUpgradeRequest | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    orig_inventory: value.origInventory,
    expected_item: value.expectedItem,
  };
}
