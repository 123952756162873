import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import i18n_yfWm7jX06p from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import api_gen_YFjFjUkJWh from "/app/plugins/api-gen.ts";
import app_error_logger_MPDClJPsYE from "/app/plugins/app-error-logger.ts";
import chunk_errors_client_tdDLVWH9ke from "/app/plugins/chunk-errors.client.ts";
import firebase_client_zXNDa0wxFH from "/app/plugins/firebase.client.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import tooltip_client_Mj8x1saCmV from "/app/plugins/tooltip.client.ts";
import unleash_hVeFqFA3j6 from "/app/plugins/unleash.ts";
import vue_error_logger_VqRcc6CYdh from "/app/plugins/vue-error-logger.ts";
import vue3_touch_events_client_ia14fW7uF5 from "/app/plugins/vue3-touch-events.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  i18n_yfWm7jX06p,
  api_gen_YFjFjUkJWh,
  app_error_logger_MPDClJPsYE,
  chunk_errors_client_tdDLVWH9ke,
  firebase_client_zXNDa0wxFH,
  sentry_client_shVUlIjFLk,
  tooltip_client_Mj8x1saCmV,
  unleash_hVeFqFA3j6,
  vue_error_logger_VqRcc6CYdh,
  vue3_touch_events_client_ia14fW7uF5
]