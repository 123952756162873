import type { AUTH_SOURCE } from "~/types/auth";

declare global {
  function ym(
    id: number,
    method: string,
    param1: string | number | Record<string, any>,
    param2?: string | number | Record<string, any>,
  ): void;
}

/**
 * Класс отвечающий за работу с яндекс-метрикой.
 * Ф-я ym является глобальной и устанавливается в index.html.
 *
 * @see https://yandex.ru/support/metrica/code/counter-spa-setup.html
 * @see https://yandex.ru/support/metrica/code/counter-initialize.html
 * @see https://yandex.ru/support/search-results/?service=metrica&query=setuserid
 * @see https://yandex.ru/support/metrica/general/goal-js-event.html#js-event
 */
export class YandexMetrika {
  static readonly id: number = 86526562;

  /**
   * Указать id текущего пользователя.
   *
   * @param id - id пользователя.
   */
  static user(id: string | number) {
    if (!isProd() || !isClient()) return;
    ym(this.id, "setUserID", id);
  }

  /**
   * Зафиксировать достижение цели.
   *
   * @param goalName - наименование цели.
   */
  static goal(goalName: string) {
    ym(this.id, "reachGoal", goalName);
  }

  /**
   * Зафиксировать регистрацию пользователя.
   */
  static registration(type: AUTH_SOURCE) {
    if (!isProd() || !isClient()) {
      return;
    }
    this.goal(`registration_${type}`);
  }

  /**
   * Зафиксировать авторизацию пользователя.
   */
  static login(type: AUTH_SOURCE) {
    if (!isProd() || !isClient()) {
      return;
    }
    this.goal(`login_${type}`);
  }

  /**
   * Зафиксировать просмотр страницы.
   *
   * @param path - путь текущей просматриваемой страницы.
   */
  static pageview(path: string) {
    if (!isProd() || !isClient()) return;
    ym(this.id, "hit", `${location.origin}${path}`);
  }

  /**
   * Указать, что используется pwa.
   */
  static pwaActiveUser() {
    if (!isProd() || !isClient()) return;
    this.goal("PWA--daily-active-user");
  }
}
