import { useBossStore } from "~/stores/bossBattle";

/**
 * Сбрасывает все состояние игры.
 */
export default function dropState() {
  const store: ReturnType<typeof useBossStore> = useBossStore();

  clearTimeout(store.reconnectionTimer);
  clearTimeout(store.initMainViewTimer);
  clearTimeout(store.timerTimer);
  clearTimeout(store.participantsTimer);

  if (store.connection?.readyState === WebSocket.OPEN) {
    store.connection.close();
  }

  store.blocked = false;
  store.connectionIsLost = false;
  store.boss = null;
  store.bossIsAvailable = false;
  store.bossHealthPercent = 0;
  store.bossHealthPoints = 0;
  store.bossHealthTotal = 0;
  store.bossTotalDamage = 0;
  store.bossEndDate = undefined;
  store.timerEndDate = undefined;
  store.timerDays = "00";
  store.timerHours = "00";
  store.timerMinutes = "00";
  store.timerSeconds = "00";
  store.timerTimer = undefined;
  store.attackOptions = [];
  store.lastAttackName = "";
  store.profile = null;
  store.showRewardPopup = false;
  store.showInfoPopup = false;
  store.rewardPopupData = null;
  store.reborn = false;
  store.reconnectionTimer = undefined;
  store.initMainViewTimer = undefined;
  store.participants = [];
  store.nextParticipantsLink = "";
  store.currentUser = null;
  store.participantsTimer = undefined;
}
