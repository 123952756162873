// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface LadderGameSettings
 */
export interface LadderGameSettings {
  /**
   *
   * @type {string}
   * @memberof LadderGameSettings
   */
  paybackCoefficient: string;
  /**
   * Рассчитанные коффициенты для любого возможного количества бомб (1-MAX_BOMB_NUMBER) для каждого этажа
   * return:
   * [
   *     [1.0, 1.06, 1.12, 1.19, 1.27, 1.36, 1.46, 1.58, 1.72, 1.89, 2.1, 2.36], # для одной бомбы
   *     [.., .., ....], # для двух
   *     ...
   * ]
   * @type {Array<Array<number>>}
   * @memberof LadderGameSettings
   */
  readonly coefficients: Array<Array<number>>;
}

/**
 * Check if a given object implements the LadderGameSettings interface.
 */
export function instanceOfLadderGameSettings(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "paybackCoefficient" in value;
  isInstance = isInstance && "coefficients" in value;

  return isInstance;
}

export function LadderGameSettingsFromJSON(json: any): LadderGameSettings {
  return LadderGameSettingsFromJSONTyped(json, false);
}

export function LadderGameSettingsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): LadderGameSettings {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    paybackCoefficient: json["payback_coefficient"],
    coefficients: json["coefficients"],
  };
}

export function LadderGameSettingsToJSON(
  value?: LadderGameSettings | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    payback_coefficient: value.paybackCoefficient,
  };
}
