// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { CurrencyEnum } from "./CurrencyEnum";
import {
  CurrencyEnumFromJSON,
  CurrencyEnumFromJSONTyped,
  CurrencyEnumToJSON,
} from "./CurrencyEnum";

/**
 *
 * @export
 * @interface ConfirmRequest
 */
export interface ConfirmRequest {
  /**
   *
   * @type {CurrencyEnum}
   * @memberof ConfirmRequest
   */
  currency: CurrencyEnum;
  /**
   *
   * @type {string}
   * @memberof ConfirmRequest
   */
  transactionId: string;
  /**
   *
   * @type {string}
   * @memberof ConfirmRequest
   */
  orderId: string;
  /**
   *
   * @type {string}
   * @memberof ConfirmRequest
   */
  pares: string;
  /**
   *
   * @type {string}
   * @memberof ConfirmRequest
   */
  md: string;
}

/**
 * Check if a given object implements the ConfirmRequest interface.
 */
export function instanceOfConfirmRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "currency" in value;
  isInstance = isInstance && "transactionId" in value;
  isInstance = isInstance && "orderId" in value;
  isInstance = isInstance && "pares" in value;
  isInstance = isInstance && "md" in value;

  return isInstance;
}

export function ConfirmRequestFromJSON(json: any): ConfirmRequest {
  return ConfirmRequestFromJSONTyped(json, false);
}

export function ConfirmRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ConfirmRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    currency: CurrencyEnumFromJSON(json["currency"]),
    transactionId: json["transaction_id"],
    orderId: json["order_id"],
    pares: json["pares"],
    md: json["md"],
  };
}

export function ConfirmRequestToJSON(value?: ConfirmRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    currency: CurrencyEnumToJSON(value.currency),
    transaction_id: value.transactionId,
    order_id: value.orderId,
    pares: value.pares,
    md: value.md,
  };
}
