// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * * `RUB` - Рубль
 * * `USD` - Доллар
 * * `EUR` - Евро
 * @export
 */
export const CurrencyEnum = {
  Rub: "RUB",
  Usd: "USD",
  Eur: "EUR",
} as const;
export type CurrencyEnum = (typeof CurrencyEnum)[keyof typeof CurrencyEnum];

export function CurrencyEnumFromJSON(json: any): CurrencyEnum {
  return CurrencyEnumFromJSONTyped(json, false);
}

export function CurrencyEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CurrencyEnum {
  return json as CurrencyEnum;
}

export function CurrencyEnumToJSON(value?: CurrencyEnum | null): any {
  return value as any;
}
