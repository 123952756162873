// @ts-nocheck
/* eslint-disable */
/**
 * Bulldrop API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  ConfirmRequest,
  GamelightInvoiceRequest,
  GamelightVaucherRequest,
  GetPayNotify,
  GetPayNotifyRequest,
  GetPaymentAmount,
  InvoiceRequest,
  OrderStatusRequest,
  PaymentGroup,
  PaymentMethod,
  PaymentMethodRate,
  PaymentMethodRateRequest,
  Scenario,
} from "../models/index";
import {
  ConfirmRequestFromJSON,
  ConfirmRequestToJSON,
  GamelightInvoiceRequestFromJSON,
  GamelightInvoiceRequestToJSON,
  GamelightVaucherRequestFromJSON,
  GamelightVaucherRequestToJSON,
  GetPayNotifyFromJSON,
  GetPayNotifyToJSON,
  GetPayNotifyRequestFromJSON,
  GetPayNotifyRequestToJSON,
  GetPaymentAmountFromJSON,
  GetPaymentAmountToJSON,
  InvoiceRequestFromJSON,
  InvoiceRequestToJSON,
  OrderStatusRequestFromJSON,
  OrderStatusRequestToJSON,
  PaymentGroupFromJSON,
  PaymentGroupToJSON,
  PaymentMethodFromJSON,
  PaymentMethodToJSON,
  PaymentMethodRateFromJSON,
  PaymentMethodRateToJSON,
  PaymentMethodRateRequestFromJSON,
  PaymentMethodRateRequestToJSON,
  ScenarioFromJSON,
  ScenarioToJSON,
} from "../models/index";

export interface PaymentsApiGamelightRequest {
  gamelightInvoiceRequest: GamelightInvoiceRequest;
}

export interface PaymentsApiGetpayNotifyCreateRequest {
  getPayNotifyRequest: GetPayNotifyRequest;
}

export interface PaymentsApiPaymentMethodRateCreateRequest {
  paymentMethodRateRequest: PaymentMethodRateRequest;
}

export interface PaymentsApiPayselectionConfirmRequest {
  confirmRequest: ConfirmRequest;
}

export interface PaymentsApiPayselectionStatusRequest {
  orderStatusRequest: OrderStatusRequest;
}

export interface PaymentsApiTopUpBalanceCreateRequest {
  paymentMethod: string;
  invoiceRequest?: InvoiceRequest;
}

export interface PaymentsApiTopUpBalanceVoucherRequest {
  gamelightVaucherRequest: GamelightVaucherRequest;
}

/**
 *
 */
export class PaymentsApi extends runtime.BaseAPI {
  /**
   */
  async amountRetrieveRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GetPaymentAmount>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/payments/amount/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetPaymentAmountFromJSON(jsonValue),
    );
  }

  /**
   */
  async amountRetrieve(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GetPaymentAmount> {
    const response = await this.amountRetrieveRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async gamelightRaw(
    requestParameters: PaymentsApiGamelightRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters["gamelightInvoiceRequest"] == null) {
      throw new runtime.RequiredError(
        "gamelightInvoiceRequest",
        'Required parameter "gamelightInvoiceRequest" was null or undefined when calling gamelight().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/payments/gamelight/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: GamelightInvoiceRequestToJSON(
          requestParameters["gamelightInvoiceRequest"],
        ),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async gamelight(
    requestParameters: PaymentsApiGamelightRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.gamelightRaw(requestParameters, initOverrides);
  }

  /**
   */
  async getpayNotifyCreateRaw(
    requestParameters: PaymentsApiGetpayNotifyCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GetPayNotify>> {
    if (requestParameters["getPayNotifyRequest"] == null) {
      throw new runtime.RequiredError(
        "getPayNotifyRequest",
        'Required parameter "getPayNotifyRequest" was null or undefined when calling getpayNotifyCreate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/payments/getpay/notify/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: GetPayNotifyRequestToJSON(
          requestParameters["getPayNotifyRequest"],
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetPayNotifyFromJSON(jsonValue),
    );
  }

  /**
   */
  async getpayNotifyCreate(
    requestParameters: PaymentsApiGetpayNotifyCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GetPayNotify> {
    const response = await this.getpayNotifyCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async methodsByGroupsListRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<PaymentGroup>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/payments/methods_by_groups/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(PaymentGroupFromJSON),
    );
  }

  /**
   */
  async methodsByGroupsList(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<PaymentGroup>> {
    const response = await this.methodsByGroupsListRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async methodsListRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<PaymentMethod>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/payments/methods/`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(PaymentMethodFromJSON),
    );
  }

  /**
   */
  async methodsList(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<PaymentMethod>> {
    const response = await this.methodsListRaw(initOverrides);
    return await response.value();
  }

  /**
   */
  async paymentMethodRateCreateRaw(
    requestParameters: PaymentsApiPaymentMethodRateCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<PaymentMethodRate>> {
    if (requestParameters["paymentMethodRateRequest"] == null) {
      throw new runtime.RequiredError(
        "paymentMethodRateRequest",
        'Required parameter "paymentMethodRateRequest" was null or undefined when calling paymentMethodRateCreate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/payments/payment_method_rate`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PaymentMethodRateRequestToJSON(
          requestParameters["paymentMethodRateRequest"],
        ),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PaymentMethodRateFromJSON(jsonValue),
    );
  }

  /**
   */
  async paymentMethodRateCreate(
    requestParameters: PaymentsApiPaymentMethodRateCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<PaymentMethodRate> {
    const response = await this.paymentMethodRateCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async payselectionConfirmRaw(
    requestParameters: PaymentsApiPayselectionConfirmRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters["confirmRequest"] == null) {
      throw new runtime.RequiredError(
        "confirmRequest",
        'Required parameter "confirmRequest" was null or undefined when calling payselectionConfirm().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/payments/payselection/confirm/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: ConfirmRequestToJSON(requestParameters["confirmRequest"]),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async payselectionConfirm(
    requestParameters: PaymentsApiPayselectionConfirmRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.payselectionConfirmRaw(requestParameters, initOverrides);
  }

  /**
   */
  async payselectionStatusRaw(
    requestParameters: PaymentsApiPayselectionStatusRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters["orderStatusRequest"] == null) {
      throw new runtime.RequiredError(
        "orderStatusRequest",
        'Required parameter "orderStatusRequest" was null or undefined when calling payselectionStatus().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/payments/payselection/status/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: OrderStatusRequestToJSON(requestParameters["orderStatusRequest"]),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async payselectionStatus(
    requestParameters: PaymentsApiPayselectionStatusRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.payselectionStatusRaw(requestParameters, initOverrides);
  }

  /**
   */
  async payselectionTermCreateRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/payments/payselection/term/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async payselectionTermCreate(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.payselectionTermCreateRaw(initOverrides);
  }

  /**
   */
  async topUpBalanceCreateRaw(
    requestParameters: PaymentsApiTopUpBalanceCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Scenario>> {
    if (requestParameters["paymentMethod"] == null) {
      throw new runtime.RequiredError(
        "paymentMethod",
        'Required parameter "paymentMethod" was null or undefined when calling topUpBalanceCreate().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/payments/top-up-balance/{payment_method}/`.replace(
          `{${"payment_method"}}`,
          encodeURIComponent(String(requestParameters["paymentMethod"])),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: InvoiceRequestToJSON(requestParameters["invoiceRequest"]),
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ScenarioFromJSON(jsonValue),
    );
  }

  /**
   */
  async topUpBalanceCreate(
    requestParameters: PaymentsApiTopUpBalanceCreateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Scenario> {
    const response = await this.topUpBalanceCreateRaw(
      requestParameters,
      initOverrides,
    );
    return await response.value();
  }

  /**
   */
  async topUpBalanceVoucherRaw(
    requestParameters: PaymentsApiTopUpBalanceVoucherRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters["gamelightVaucherRequest"] == null) {
      throw new runtime.RequiredError(
        "gamelightVaucherRequest",
        'Required parameter "gamelightVaucherRequest" was null or undefined when calling topUpBalanceVoucher().',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("tokenAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/payments/top-up-balance/voucher/`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: GamelightVaucherRequestToJSON(
          requestParameters["gamelightVaucherRequest"],
        ),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async topUpBalanceVoucher(
    requestParameters: PaymentsApiTopUpBalanceVoucherRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.topUpBalanceVoucherRaw(requestParameters, initOverrides);
  }
}
