import type { PlatformName, PlatformData } from "~/types/Platform";
import { normalize } from "~/utils/url/normalize";
import { getLocale } from "~/utils/url/getLocale";
import { useMonthCookie } from "~/utils/cookie";

export const usePlatformStore = defineStore("platformStore", () => {
  const DEFAULT_LANGUAGE = "ru";
  const DEFAULT_PLATFORM: PlatformName = "standoff";
  const ALL_PLATFORMS_DATA = {
    standoff: {
      title: "Standoff 2",
      code: "standoff",
      className: "bdrop-theme-standoff",
      img: "standoff",
      tg: "https://t.me/bulldron",
      cypherTapTg: "https://t.me/cyphertap",
      mainColor: "#1C193D",
      basePath: "/",
    },
    cs: {
      title: "CS2",
      code: "cs",
      className: "bdrop-theme-cs2",
      img: "pubg",
      tg: "https://t.me/bulldropcs2",
      cypherTapTg: "https://t.me/cyphertap",
      mainColor: "#0A0A0A",
      basePath: "/cs",
    },
    pubg: {
      title: "PUBGM",
      code: "pubg",
      className: "bdrop-theme-pubg",
      img: "pubg",
      tg: "https://t.me/bulldrop_pubg",
      cypherTapTg: "https://t.me/cyphertap",
      mainColor: "#1A1B1F",
      basePath: "/pubg",
    },
    roblox: {
      title: "Roblox",
      code: "roblox",
      className: "bdrop-theme-roblox",
      img: "pubg",
      tg: "https://t.me/bulldroproblox",
      cypherTapTg: "https://t.me/cyphertap",
      mainColor: "#1A1B1F",
      basePath: "/roblox",
    },
    genshin: {
      title: "Genshin Impact",
      code: "genshin",
      className: "bdrop-theme-genshinImpact",
      img: "genshin",
      tg: "https://t.me/bulldrop_genshin",
      cypherTapTg: "https://t.me/cyphertap",
      mainColor: "#1f2733",
      basePath: "/genshin",
    },
  } as const satisfies Record<PlatformName, PlatformData>;

  const currentPlatformName = ref<PlatformName>(DEFAULT_PLATFORM);
  const currentPlatformPath = ref("/");
  const currentPlatformFullPath = ref("/");

  type ChangeOptions = {
    preserveParams: boolean;
  };

  const changePlatform = (
    to: PlatformName,
    options: ChangeOptions = { preserveParams: true },
  ) => {
    const platformCookie = useMonthCookie("platform");
    platformCookie.value = to;
    if (currentPlatformName.value === to) return;
    const path = options.preserveParams
      ? currentPlatformFullPath.value
      : currentPlatformPath.value;
    const redirect = (url: string) =>
      navigateTo(normalize(url), { replace: true });
    if (to === DEFAULT_PLATFORM) {
      return redirect(path.replace(currentPlatformName.value, ""));
    } else {
      if (currentPlatformName.value === DEFAULT_PLATFORM) {
        const localeInPath = getLocale(currentPlatformPath.value);
        if (localeInPath) {
          const rest = path.slice(localeInPath.length + 1);
          return redirect(`/${localeInPath}/${to}/${rest}`);
        } else {
          const localeCookie = useCookie("django_language");
          if (localeCookie.value && localeCookie.value !== DEFAULT_LANGUAGE) {
            return redirect(`/${localeCookie.value}/${to}/${path}`);
          } else {
            return redirect(`/${to}/${path}`);
          }
        }
      } else {
        return redirect(path.replace(currentPlatformName.value, to));
      }
    }
  };

  const getActiveness = (name: PlatformName) => {
    const activeness: Record<PlatformName, boolean> = {
      standoff: false,
      genshin: false,
      pubg: false,
      roblox: false,
      cs: false,
    };
    activeness[name] = true;
    return activeness;
  };

  const aggregatedPlatformData = computed(() => ({
    ...ALL_PLATFORMS_DATA[currentPlatformName.value],
    is: {
      ...getActiveness(currentPlatformName.value),
      default: currentPlatformName.value === DEFAULT_PLATFORM,
    },
    ALL_PLATFORMS_DATA,
    DEFAULT_PLATFORM,
    change: changePlatform,
  }));

  const updatePlatformPath = ({
    path,
    fullPath,
  }: {
    path: string;
    fullPath: string;
  }) => {
    currentPlatformPath.value = path;
    currentPlatformFullPath.value = fullPath;
  };

  const isPlatformLegal = (name: any) => {
    if (typeof name !== "string") return false;
    if (name === "" || name in ALL_PLATFORMS_DATA) {
      return true;
    } else {
      return false;
    }
  };

  return {
    DEFAULT_LANGUAGE,
    DEFAULT_PLATFORM,
    ALL_PLATFORMS_DATA,
    currentPlatformName,
    currentPlatformPath,
    currentPlatformFullPath,
    aggregatedPlatformData,
    changePlatform,
    updatePlatformPath,
    isPlatformLegal,
  };
});
